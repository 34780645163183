<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
      <div class="form">
        <div class="form-row">
          <div class="form-col">
            <label for="categories">main topic</label>
            <input
              type="text"
              class="form-input"
              disabled
              :value="this.category.intent_category_name"
              v-if="this.category"
            />
            <label for="language" class="mt-4">language</label>
            <Dropdown
              trackBy="label"
              label="label"
              :options="[{label: 'english', value: 'en'}, {label: 'spanish', value: 'es'}]"
              :value="language"
              @input="setLanguage"
              :disabled="!subIntent"
            />
          </div>
          <div class="form-col">
            <label for="categories">conversation detail</label>
            <input
              type="text"
              class="form-input"
              disabled
              :value="this.intent.suggestion_text"
              v-if="this.category && this.intent"
            />
          </div>
          <div class="form-col">
            <label for="categories">next level detail</label>
            <input
              type="text"
              class="form-input"
              disabled
              :value="this.subIntent.suggestion_text"
              v-if="this.category && this.intent && this.subIntent"
            />
          </div>
        </div>
      </div>
      <div
        class="form overflow-auto mt-8"
      >
      <div class="form-row">
        <div class="form-col">
          <label for="generated_intent_name">intent name</label>
            <input
              type="text"
              class="form-input"
              disabled
              :value="this.intent_name"
            />
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>input context</label>
          <div class="container-element">
            <div class="mb-4" v-if="this.input_context.length > 0">
              <div class="element bordered flex items-center justify-between" v-for="(i_value, i) in this.input_context" :key="i">
                <span>{{i_value}}</span>
                <button
                  class="btn-action-warning focus:outline-none"
                  type="submit"
                  @click="() => this.deleteInput(i)"
                >
                  <icon class="w-5 h-5 mr-1" name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <input
              type="text"
              class="form-input"
              v-model="input_value"
            />
            <button :disabled="loading" class="btn-primary ml-2" type="submit" @click="addInput">add</button>
          </div>
        </div>
        <div class="form-col">
          <div class="flex">
            <div class="w-3/4 mr-1">
              <label>output context</label>
            </div>
            <div class="w-1/5">
              <label>span</label>
            </div>
          </div>
          <div class="flex mb-4" v-if="this.output_context.length > 0">
            <div class="w-3/4 mr-1">
              <div class="container-element">
                <div class="element bordered flex items-center justify-between" v-for="i in this.output_context" :key="i.name">
                  {{i.name}}
                  <button
                    class="btn-action-warning focus:outline-none"
                    type="submit"
                    @click="() => this.deleteOutput(i)"
                  >
                    <icon class="w-5 h-5 mr-1" name="trash"/>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-1/5">
              <div class="container-element">
                <div class="element bordered" v-for="i in this.output_context" :key="i.lifespan">{{i.lifespan}}</div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="w-2/3 mr-1">
              <input
                type="text"
                placeholder="output context"
                class="form-input"
                v-model="output_value"
              />
            </div>
            <div class="w-1/5">
              <input
                type="text"
                placeholder="span"
                class="form-input"
                v-model="span_value"
              />
            </div>
            <button :disabled="loading" type="submit" class="btn-primary ml-2" @click="addOutput">add</button>
          </div>
        </div>
        <div class="form-col">
          <label>intent description</label>
            <textarea type="text" class="form-input" id="text" v-model="text" />
            <span v-if="hasError('text')" class="form-error">
              {{ showError('text') }}
            </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>dialogflow failed webhook response</label>
          <div class="container-element">
            <div class="mb-4" v-if="this.text_response.length > 0">
              <div class="element bordered  flex items-center justify-between" v-for="(i_value, i) in this.text_response" :key="i">
                <span>{{i_value}}</span>
                <button
                  class="btn-action-warning focus:outline-none"
                  type="submit"
                  @click="() => this.deleteWebhook(i)"
                >
                  <icon class="w-5 h-5 mr-1" name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <input
              type="text"
              class="form-input"
              v-model="webhook_value"
            />
            <button :disabled="loading" class="btn-primary ml-2" @click="addWebhook">add</button>
          </div>
          <span v-if="this.array_errors.text_response" class="form-error">
            {{ this.array_errors.text_error }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>training phrases</label>
          <div class="container-element">
            <div class="mb-4" v-if="this.training_phrases.length > 0">
              <div class="element bordered  flex items-center justify-between" v-for="(i_value, i) in this.training_phrases" :key="i">
                <span>{{i_value}}</span>
                <button
                  class="btn-action-warning focus:outline-none"
                  type="submit"
                  @click="() => this.deleteTraining(i)"
                >
                  <icon class="w-5 h-5 mr-1" name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <input
              type="text"
              class="form-input"
               v-model="training_value"
            />
            <button :disabled="loading" class="btn-primary ml-2" @click="addTraining">add</button>
          </div>
          <span v-if="this.array_errors.training_phrases" class="form-error">
            {{ this.array_errors.training_error }}
          </span>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="save" />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'
import Icon from "@/components/ui/Icon";

export default {
  name: 'CreateResponse',
  components: { Loader, Dropdown, ModalFooter, Icon },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, ConversationMixin],
  data() {
    return {
      language: {label: 'english', value: 'en'},
      input_context: [],
      training_phrases: [],
      text_response: [],
      output_context: [],
      input_value: '',
      training_value: '',
      webhook_value: '',
      output_value: '',
      span_value: '',
      text: '',
      footer: {
        primaryButton: 'save',
      },
      array_errors: {
        text_response: false,
        text_error: '',
        training_phrases: false,
        training_error: ''
      }
    }
  },
  methods: {
    setLanguage(value) {
      this.language = null;
      if(!value) return
      this.language = value;
      const subIntentValue =
      this.subIntent.intent_name === 'general' ? this.intent : this.subIntent
      this.fetchIntent(subIntentValue.intent_name)
    },
    addInput() {
      if(!this.input_value.trim()) return;
      this.input_context.push(this.input_value);
      this.input_value = '';
    },
    deleteInput(i) {
      this.input_context.splice(i, 1);
    },
    addTraining() {
      if(!this.training_value.trim()) return;
      this.training_phrases.push(this.training_value);
      this.training_value = '';
    },
    deleteTraining(i) {
      this.training_phrases.splice(i, 1);
    },
    addWebhook() {
      if(!this.webhook_value.trim()) return;
      this.text_response.push(this.webhook_value);
      this.webhook_value = '';
    },
    deleteWebhook(i) {
      this.text_response.splice(i, 1);
    },
    addOutput() {
      if(!this.output_value.trim() || !this.span_value.trim()) return;
      this.output_context.push({ name: this.output_value, lifespan: this.span_value });
      this.output_value = '';
      this.span_value = '';
    },
    deleteOutput(i) {
      this.output_context.splice(i, 1);
    },
    redirectToList() {
      this.$router.replace({
        name: 'manage_intents.index',
      })
    },
    fetchIntent(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .get('getIntent', {
          language: encodeURIComponent(this.language.value),
          intent: encodeURIComponent(intentName),
        })
        .then((res) => {
          this.intent_name = res.intent_name;
          this.parent_suggestion_text = res.parent_suggestion_text;
          this.suggestion_text = res.suggestion_text;
          this.input_context = res.input_context;
          this.output_context = res.ouput_context;
          this.text = res.intent_description;
          this.text_response = res.text_response;
          this.training_phrases = res.training_phrases;
        })
        .catch((error) => {
          this.redirectToList()
          this.notifyError(error.message)
        })
        .finally(() => this.setLoading(false))
    },
    save() {
      this.array_errors = {
        text_response: false,
        text_error: '',
        training_phrases: false,
        training_error: ''
      }

      const text_response_error = this.text_response.length === 0;
      const training_phrases_error = this.training_phrases.length === 0;

      if(text_response_error || training_phrases_error) {
        this.array_errors = {
          text_response: text_response_error ,
          text_error: text_response_error ? 'this field is empty' : '',
          training_phrases: training_phrases_error,
          training_error: training_phrases_error ? 'this field is empty' : '',
        }
        this.setLoading(false)
        return;
      }

      if (!this.validator.passes()) return

      this.setLoading(true)

      const value =
        this.subIntent?.intent_name === 'general'
          ? this.intent
          : this.subIntent

      this.$dhDataProvider
        .update('editIntent', {
          id: encodeURIComponent(value.intent_name),
          data: {
            intent_description: this.text,
            input_context: this.input_context.filter((resp) => Boolean(resp.trim())),
            output_context: this.output_context.filter((resp) => Boolean(resp.name.trim())),
            training_phrases: this.training_phrases.filter((resp) => Boolean(resp.trim())),
            text_response: this.text_response.filter((resp) => Boolean(resp.trim())),
            language: this.language.value
          },
        })
        .then(() => {
          this.notifySuccess('The intent was edited successfully')
        })
        .catch(error => {
          this.notifyError(error.message)
        })
        .finally(() => this.setLoading(false))

    },
  },
  validations: {
    text: 'required | is_empty',
  },
  created() {
    this.initValidator()
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'text.is_empty': 'this field is empty.',
    })
    if (this.category && this.intent && this.subIntent && this.language) {
      const value =
        this.subIntent?.intent_name === 'general'
          ? this.intent
          : this.subIntent
      this.fetchIntent(value.intent_name)
    } else {
      this.redirectToList()
      this.notifyError('please select the intent to edit on the previous screen')
    }
  },
}
</script>
<style scoped>
.isDisabled {
  @apply text-gray-400 !important;
}
.element {
    @apply w-full font-400 h-10 text-base;
}
.bordered {
  @apply border border-gray-300 rounded-xs outline-none py-2 px-3;
}
.container-element {
  max-height: 10rem;
  overflow: auto;
}
</style>
